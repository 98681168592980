import { appCreateStyles } from '@core/theme/utils/create-styles';
import { appWithStyles } from '@core/theme/utils/with-styles';
import { FormHelperText, TextField } from '@mui/material';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export const styles = () =>
  appCreateStyles({
    root: {
      width: '100%',
    },
  });

export const StyledTextField = appWithStyles(() => ({
  root: {},
  focused: {},
}))(TextField);

export const StyledFormHelperText = appWithStyles(() => ({
  root: {
    fontSize: '15px',
    fontWeight: 600,
    lineHeight: '160%',
    letterSpacing: '0.2px',
    marginLeft: '0px',
    marginRight: '0px',
    bottom: '-34px',
    color: _var(CSS_VARIABLES.colorAdditionalWhite),
  },
}))(FormHelperText);
