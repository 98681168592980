import React from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Theme, useMediaQuery } from '@mui/material';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { Layout } from '@shared/utils/layout';

import ArrowSvg from './img/arrow-right.svg';
import WarningSvg from './img/warning.svg';

import { styles } from './maintenance-mode-label.styles';

export interface MaintenanceModeLabelProps extends AppWithStyles<typeof styles> {}

const MaintenanceModeLabelComponent: React.FC<MaintenanceModeLabelProps> = ({ classes }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));
  const navigateToMaintenancePage = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.maintenancePage)
      : navigate(ROUTES.desktopPrivate.maintenancePage);
  };

  return (
    <div className={classes.root} onClick={navigateToMaintenancePage}>
      <img className={classes.warningIcon} src={String(WarningSvg)} alt={''} />
      <img className={classes.arrowRightIcon} src={String(ArrowSvg)} alt={''} />
      <div className={classes.text}>{t`System is under maintenance, some of operations...`}</div>
    </div>
  );
};

export default appWithStyles(styles)(MaintenanceModeLabelComponent);
