import { appCreateStyles } from '@core/theme/utils/create-styles';

export function styles() {
  return appCreateStyles({
    root: {
      display: 'flex',
      width: '100%',
      height: '32px',
      padding: '7px 40px',
      alignItems: 'center',
      backgroundColor: '#CE4347',
      position: 'relative',
      justifyContent: 'center',
    },
    warningIcon: {
      position: 'absolute',
      left: '16px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    arrowRightIcon: {
      position: 'absolute',
      right: '16px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    text: {
      color: '#FFF',
      fontSize: '13px',
      fontWeight: 500,
      letterSpacing: '0.2px',
      width: 'fit-content',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textWrap: 'nowrap',
    },
  });
}
