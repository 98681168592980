import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      width: '355px',
      height: '52px',
      borderRadius: '10px',
      background: '#464660',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '0px 40px 0px 50px',
      color: '#fff',
      fontSize: '13px',
      fontWeight: 500,
      letterSpacing: '0.2px',
      position: 'relative',
      boxShadow: '0px 4px 14px 0px rgba(1, 1, 1, 0.20)',
      [theme.breakpoints.down(Layout.tablet)]: {
        width: '100%',
      },
    },
    topUp: {},
    sellFiatToken: {},
    arrowIcon: {
      position: 'absolute',
      left: '16px',
      top: '50%',
      transform: 'translate(0%, -50%)',
    },
    closeIcon: {
      position: 'absolute',
      right: '16px',
      top: '50%',
      transform: 'translate(0%, -50%)',
      cursor: 'pointer',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      marginTop: '1px',
    },
    infoBlock: {
      display: 'flex',
    },
    amount: {
      marginLeft: '5px',
    },
    currency: {
      marginLeft: '5px',
    },
    status: {},
    successfull: {
      '& $status': {
        color: '#5AD485',
      },
    },
    inProgress: {
      '& $status': {
        color: '#AB8DFF',
      },
    },
    canceled: {
      '& $status': {
        color: '#F95A5F',
      },
    },
  });
}
