import React from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { NotificationMessageType } from '@shared/components/new-design/notifications/notification-message/message-type';
import classNames from 'classnames';

import TFAIcon from './img/2fa.svg';
import CloseIcon from './img/close.svg';
import NotificationIcon from './img/notification.svg';
import WarningIcon from './img/warning.svg';

import { styles } from './notification-message.styles';

export type SuccessMessageProps = AppWithStyles<any> & {
  text: string;
  type: NotificationMessageType;
};

const SuccessMessageComponent: React.FC<SuccessMessageProps> = ({ classes, text, type }) => {
  let icon = '';
  switch (true) {
    case type === NotificationMessageType.WARNING:
      icon = String(WarningIcon);
      break;
    case type === NotificationMessageType.TFA:
      icon = String(TFAIcon);
      break;
    default:
      icon = String(NotificationIcon);
  }

  return (
    <div
      className={classNames(classes.root, {
        [classes.info as string]: [
          NotificationMessageType.INFO,
          NotificationMessageType.TFA,
        ].includes(type),
        [classes.warning as string]: type === NotificationMessageType.WARNING,
      })}
    >
      <div className={classes.content}>
        <div className={classes.infoBlock}>
          <div className={classes.text}>{text}</div>
        </div>
      </div>
      <div className={classes.icon}>
        <img src={icon} />
      </div>
      <div
        className={classNames({
          [classes.closeIcon as string]: type !== NotificationMessageType.WARNING,
          [classes.hidden as string]: type === NotificationMessageType.WARNING,
        })}
      >
        <img src={String(CloseIcon)} />
      </div>
    </div>
  );
};

export default appWithStyles(styles)(SuccessMessageComponent);
