import React from 'react';

import { appInject } from '@core/di/utils';
import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DI_TOKENS } from '@shared/constants/di';
import { OrderDetailsType } from '@shared/enums/order-details-type';
import { ISmartCoinDetailsDialogViewModel } from '@shared/types/smart-coin-details-dialog-view-model';
import { SmartCoinOrderStatusExternal } from '@shared/types/smart-coin-order-status';
import classNames from 'classnames';

import ArrowRightIcon from './img/arrow-right.svg';
import PurchaseIcon from './img/purchase.svg';
import SellIcon from './img/sell.svg';

import { styles } from './smart-coin-order-message.styles';

export type SmartCoinOrderMessageProps = AppWithStyles<any> & {
  id: string;
  status: SmartCoinOrderStatusExternal;
  quantity: string;
  smartCoin: string;
  isBuy: boolean;
};

const SmartCoinOrderMessageComponent: React.FC<SmartCoinOrderMessageProps> = ({
  classes,
  id,
  status,
  quantity,
  smartCoin,
  isBuy,
}) => {
  const smartCoinDetailsDialogVM = appInject<ISmartCoinDetailsDialogViewModel>(
    DI_TOKENS.smartCoinDetailsDialogViewModel,
  );

  const navigateToOrderDetails = () => {
    smartCoinDetailsDialogVM.openDialog(id, OrderDetailsType.PURCHASE_OR_SELL_SMART_COIN);
  };

  let statusClass = '';
  let formatedStatus = '';
  switch (status) {
    case SmartCoinOrderStatusExternal.SECURE_3D:
      statusClass = classes.secure3D as string;
      formatedStatus = t`3DS required`;
      break;
    case SmartCoinOrderStatusExternal.NEW:
    case SmartCoinOrderStatusExternal.IN_PROGRESS:
      statusClass = classes.inProgress as string;
      formatedStatus = t`In Progress`;
      break;
    case SmartCoinOrderStatusExternal.CANCELED:
      statusClass = classes.canceled as string;
      formatedStatus = t`Canceled`;
      break;
    case SmartCoinOrderStatusExternal.EXECUTED:
      statusClass = classes.successfull as string;
      formatedStatus = t`Successful`;
      break;
  }

  const icon = isBuy ? String(PurchaseIcon) : String(SellIcon);

  return (
    <div className={classNames(classes.root, statusClass)} onClick={navigateToOrderDetails}>
      <div className={classes.content}>
        <div className={classes.infoBlock}>
          {isBuy ? t`Purchase of` : t`Sell of`} {quantity} {smartCoin}
        </div>
        <div className={classes.status}>{formatedStatus}</div>
      </div>
      <div className={classes.closeIcon}>
        <img src={String(ArrowRightIcon)} />
      </div>
      <div className={classes.arrowIcon}>
        <img src={icon} />
      </div>
    </div>
  );
};

export default appWithStyles(styles)(SmartCoinOrderMessageComponent);
