import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      minWidth: '355px',
      width: 'auto',
      textWrap: 'nowrap',
      height: '52px',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '0px 40px 0px 50px',
      position: 'relative',
      boxShadow: '0px 4px 14px 0px rgba(1, 1, 1, 0.20)',
      [theme.breakpoints.down(Layout.tablet)]: {
        width: '100%',
        minWidth: '100%',
        textWrap: 'wrap',
      },
    },
    info: {
      background: '#464660',
    },
    warning: {
      background: '#CE4347',
    },
    icon: {
      position: 'absolute',
      left: '16px',
      top: '50%',
      transform: 'translate(0%, -50%)',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      marginTop: '1px',
    },
    infoBlock: {
      display: 'flex',
    },
    text: {
      color: '#fff',
      fontSize: '13px',
      fontWeight: 500,
      letterSpacing: '0.2px',
    },
    closeIcon: {
      position: 'absolute',
      right: '20px',
      top: '50%',
      transform: 'translate(0%, -50%)',
      cursor: 'pointer',
    },
    hidden: {
      display: 'none',
    },
  });
}
