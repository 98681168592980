import React from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import classNames from 'classnames';
import OtpInput from 'react-otp-input-rc-17';

import { styles } from './otp-text-field.styles';

export interface OtpTextFieldProps {
  onChange?: (value: string) => unknown;
  value?: string;
  placeholder?: string;
  hasError?: boolean;
  size: number;
}

const OtpTextFieldComponent: React.FC<OtpTextFieldProps & AppWithStyles<any>> = (props) => {
  const { classes } = props;
  const { value, size, placeholder, hasError } = props;
  const { onChange } = props;

  return (
    <OtpInput
      containerStyle={classes.container}
      inputStyle={classNames(classes.input, { [classes.error as string]: hasError })}
      shouldAutoFocus={true}
      numInputs={size}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      isInputNum={true}
    />
  );
};

export const OtpTextField = appWithStyles(styles)(OtpTextFieldComponent);
