import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import MoreButtonSvg from '../images/more-button.svg';

import { StyledIconButton, styles } from './more-button.styles';

export type MoreButtonProps = AppWithStyles<any> & {
  onClick: () => void;
};

export const MoreButtonComponent: React.FC<MoreButtonProps> = (props) => {
  const { classes, onClick } = props;
  return (
    <div onClick={onClick} className={classes.root}>
      <StyledIconButton {...props}>
        <img src={String(MoreButtonSvg)} />
      </StyledIconButton>
    </div>
  );
};

export const MoreButton = appWithStyles(styles)(MoreButtonComponent);
