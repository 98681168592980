import React from 'react';

import { appInject } from '@core/di/utils';
import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DI_TOKENS } from '@shared/constants/di';
import { OrderDetailsType } from '@shared/enums/order-details-type';
import { TransactionMessageType } from '@shared/enums/transaction-message-type';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import { ISmartCoinDetailsDialogViewModel } from '@shared/types/smart-coin-details-dialog-view-model';
import classNames from 'classnames';

import ArrowRightIcon from './img/arrow-right.svg';
import SellFiatTokenIcon from './img/sell-fiat-token.svg';
import TopUpIcon from './img/top-up.svg';
import TransferIcon from './img/transfer.svg';

import { styles } from './transactions-message.styles';

export type TransactionsMessageProps = AppWithStyles<any> & {
  status: string;
  currency: string;
  amount: number;
  type: TransactionMessageType;
  id: string;
};

const TransactionsMessageComponent: React.FC<TransactionsMessageProps> = ({
  classes,
  currency,
  status,
  amount,
  type,
  id,
}) => {
  const smartCoinDetailsDialogVM = appInject<ISmartCoinDetailsDialogViewModel>(
    DI_TOKENS.smartCoinDetailsDialogViewModel,
  );

  const navigateToOrderDetails = () => {
    if (id) {
      smartCoinDetailsDialogVM.openDialog(id, OrderDetailsType.WITHDRAWAL_FIAT_COIN);
    }
  };

  /* const handleClick = () => {
    switch (transactionType) {
      case TransactionType.PURCHASED:
      case TransactionType.SOLD:
      case TransactionType.SPENT:
      case TransactionType.EARNED:
        smartCoinDetailsDialogVM.openDialog(
            externalTransactionId as string,
            OrderDetailsType.PURCHASE_OR_SELL_SMART_COIN,
        );
        break;
      case TransactionType.WITHDRAWAL:
        smartCoinDetailsDialogVM.openDialog(id, OrderDetailsType.WITHDRAWAL_FIAT_COIN);
        break;
      case TransactionType.DEPOSIT:
        smartCoinDetailsDialogVM.openDialog(id, OrderDetailsType.DEPOSIT_FIAT_COIN);
        break;
      case TransactionType.SENT:
        smartCoinDetailsDialogVM.openDialog(id, OrderDetailsType.SENDING_SMART_COIN);
        break;
      case TransactionType.RECEIVED:
        smartCoinDetailsDialogVM.openDialog(id, OrderDetailsType.RECEIVING_SMART_COIN);
        break;
      default:
        break;
    }
  };*/

  let transactionTypeClass = '';
  let transactionTypeText = '';
  let icon = '';
  switch (type) {
    case TransactionMessageType.TOP_UP:
      transactionTypeClass = classes.topUp as string;
      transactionTypeText = t`Depositing`;
      icon = String(TopUpIcon);
      break;
    case TransactionMessageType.SELL_FIAT_TOKEN:
      transactionTypeClass = classes.sellFiatToken as string;
      transactionTypeText = t`Withdrawal`;
      icon = String(SellFiatTokenIcon);
      break;
    case TransactionMessageType.TRANSFER:
      transactionTypeClass = classes.transfer as string;
      transactionTypeText = t`Send`;
      icon = String(TransferIcon);
      break;
  }

  let statusClass = '';
  let formatedStatus = '';
  switch (status) {
    case 'inProgress':
      statusClass = classes.inProgress as string;
      formatedStatus = t`In Progress`;
      break;
    case 'canceled':
      statusClass = classes.canceled as string;
      formatedStatus = t`Canceled`;
      break;
    case 'successful':
      statusClass = classes.successfull as string;
      formatedStatus = t`Successfull`;
      break;
  }

  const closeIcon = String(ArrowRightIcon);

  return (
    <div
      className={classNames(classes.root, statusClass, transactionTypeClass)}
      onClick={navigateToOrderDetails}
    >
      <div className={classes.content}>
        <div className={classes.infoBlock}>
          <div className={classes.text}>{transactionTypeText}</div>
          <div className={classes.amount}>{minDigitsAfterDot(amountPipe(amount, 8), 2)}</div>
          <div className={classes.currency}>{currency}</div>
        </div>
        <div className={classes.status}>{formatedStatus}</div>
      </div>
      <div className={classes.closeIcon}>
        <img src={closeIcon} />
      </div>
      <div className={classes.arrowIcon}>
        <img src={icon} />
      </div>
    </div>
  );
};

export default appWithStyles(styles)(TransactionsMessageComponent);
