import React from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import { Loading } from '@shared/components/loading';

import { styles } from './outlined-button.styles';

export type OutlinedButtonProps = AppWithStyles<any> &
  ButtonProps & {
    label: string;
    isLoading?: boolean;
    disabled?: boolean;
    onSubmit: () => void;
  };

const OutlinedButtonComponent: React.FC<OutlinedButtonProps> = ({
  label,
  disabled,
  onSubmit,
  isLoading,
}) => {
  const content = isLoading ? <Loading size={30} /> : <>{label}</>;
  return (
    <Button variant="outlined" disabled={isLoading || disabled} onClick={onSubmit}>
      {content}
    </Button>
  );
};

export default appWithStyles(styles)(OutlinedButtonComponent);
