import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      height: '100%',
      width: '374px',
      padding: '0px 20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.down(Layout.tablet)]: {
        padding: '20px',
        justifyContent: 'space-between',
      },
    },
    title: {
      textAlign: 'center',
      marginBottom: '16px',
      justifyContent: 'center',
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
    },
    description: {
      textAlign: 'center',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '20px',
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
    },
    email: {
      textAlign: 'center',
      color: _var(CSS_VARIABLES.colorSecondary100),
    },
    fields: {
      marginTop: '50px',
      marginBottom: '73px',
    },
    field: {
      width: '100%',
    },
    error: {
      color: _var(CSS_VARIABLES.colorError500),
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '165%',
      letterSpacing: '0.2px',
      marginTop: '8px',
      position: 'absolute',
    },
    resend: {
      marginTop: '108px',
      marginBottom: '40px',
    },
    timer: {
      color: _var(CSS_VARIABLES.colorPrimary100),
      textAlign: 'center',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '165%',
      letterSpacing: '0.2px',
    },
    resendButton: {
      textAlign: 'center',
      color: _var(CSS_VARIABLES.colorSecondary100),
      cursor: 'pointer',
    },
  });
}
