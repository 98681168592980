import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Box, Modal } from '@mui/material';
import classNames from 'classnames';

import { styles } from './logout-dialog.styles';

export interface LogoutDialogProps extends AppWithStyles<typeof styles> {
  isOpen: boolean;
  onClose: () => void;
  onLogout: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 270,
  height: 189,
  border: 'none',
  borderRadius: '14px',
  background: 'rgba(37, 37, 37, 0.82)',
  outline: 'none',
};

const LogoutDialogComponent: FC<LogoutDialogProps> = ({ classes, onClose, isOpen, onLogout }) => {
  const hadnleLogout = () => {
    onLogout && onLogout();
    onClose && onClose();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const content = (
    <div className={classes.viewBlock}>
      <div className={classNames(classes.titleBlock, classes.menuItem)}>
        <div className={classes.title}>{t`Log out`}</div>
        <div className={classes.description}>{t`Are you sure you want to log out now?`}</div>
      </div>
      <div
        className={classNames(classes.logoutButton, classes.menuItem)}
        onClick={hadnleLogout}
      >{t`Logout`}</div>
      <div
        className={classNames(classes.cancelButton, classes.menuItem)}
        onClick={handleClose}
      >{t`Cancel`}</div>
    </div>
  );

  return (
    <div className={classes.root}>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="logout-dialog-label"
        aria-describedby="drawer-dialog-description"
      >
        <Box sx={style}>{content}</Box>
      </Modal>
    </div>
  );
};

export default appWithStyles(styles)(LogoutDialogComponent);
