import { appCreateStyles } from '@core/theme/utils/create-styles';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export const styles = () =>
  appCreateStyles({
    container: {
      justifyContent: 'center',
    },
    input: {
      width: '50px !important',
      height: '50px',
      border: '1px solid #87878C',
      borderRadius: '12px',
      color: _var(CSS_VARIABLES.colorPrimary100),
      textAlign: 'center',
      fontSize: '28px',
      fontWeight: 500,
      backgroundColor: 'transparent',
      fontFamily: 'inherit',
      margin: '0 4px',
      padding: '8px 15px',

      '&:focus': {
        outline: 'none',
        border: '1px solid #87878C',
      },
    },
  });
