import React, { useEffect, useState, KeyboardEvent } from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { Typography } from '@shared/components/typography';
import { getTimeNumber } from '@shared/utils/number';
import moment from 'moment';
import { useTimer } from 'react-timer-hook';

import { OtpTextField } from './components/otp-text-field';

import { styles } from './otp-view.styles';

export interface OtpViewProps extends AppWithStyles<typeof styles> {
  title: string | React.ReactElement;
  subTitle: string;
  email?: string;
  note?: string;
  onBack?: () => unknown;
  onResend: () => unknown;
  onSubmit: (otp: string) => unknown;
  timerDate: Date;
  timerDelayMinutes: number;
  error?: string | null;
  isLoading?: boolean;
}

const OTP_SIZE = 6;

const OtpViewComponent: React.FC<OtpViewProps> = ({
  classes,
  error,
  onSubmit,
  onResend,
  timerDate,
  timerDelayMinutes,
  title,
  subTitle,
  note,
  email,
  isLoading,
}) => {
  const { seconds, minutes, isRunning, restart } = useTimer({ expiryTimestamp: new Date() });

  const [otp, setOtp] = useState<string>('');
  const isOtpValid = otp.length === OTP_SIZE;

  const handleSubmit = () => onSubmit(otp);

  const handleRestart = () => {
    restart(moment(timerDate).add(timerDelayMinutes, 'm').toDate());
  };

  useEffect(handleRestart, [timerDate]);

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter' && isOtpValid) {
      handleSubmit();
    }
  };

  const resendComponent = isRunning ? (
    <p className={classes.timer}>
      {t`Resend Code in` +
        ' ' +
        `${getTimeNumber(minutes)}:${getTimeNumber(seconds)}` +
        ' ' +
        t`sec`}
    </p>
  ) : (
    <div onClick={onResend} className={classes.resendButton}>
      {t`Resend Code`}
    </div>
  );

  return (
    <div className={classes.root} onKeyDown={handleKeyPress}>
      <div>
        <Typography className={classes.title} variant="h3">
          {title}
        </Typography>
        <div className={classes.description}>
          {subTitle}
          {email && <span className={classes.email}>{` ${email}`}</span>}
        </div>

        <div className={classes.fields}>
          <div className={classes.field}>
            <OtpTextField
              size={OTP_SIZE}
              value={otp}
              placeholder={''.repeat(OTP_SIZE)}
              onChange={setOtp}
              hasError={Boolean(error)}
            />
            {error && <div className={classes.error}>{error}</div>}
          </div>
        </div>
      </div>
      <div>
        <div className={classes.resend}>{resendComponent}</div>
        <SubmitButton
          label={t`Submit`}
          disabled={!isOtpValid}
          isLoading={isLoading}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export const OtpView = appWithStyles(styles)(OtpViewComponent);
