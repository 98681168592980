import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { styles } from './open-drawer-button.styles';

export type CloseDrawerButtonProps = AppWithStyles<any> & {
  onClick: () => void;
};

export const CloseDrawerButtonComponent: React.FC<CloseDrawerButtonProps> = (props) => {
  const { classes, onClick } = props;
  return <div onClick={onClick} className={classes.root}></div>;
};

export const OpenDrawerButton = appWithStyles(styles)(CloseDrawerButtonComponent);
